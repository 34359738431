import axios from 'axios';
import { z } from 'zod';

import authService from './auth/auth';
import { DjangoDataZodSchema } from './common';
import { apiRoutes } from './config';

export enum JobLevelEnum {
  INTERN = 'INTERN',
  FULL_TIME = 'FULL_TIME',
  NEW_GRAD = 'NEW_GRAD',
  FREELANCE = 'FREELANCE',
  PART_TIME = 'PART_TIME',
}
export enum BulletPointsCategory {
  METRIC = 'METRIC',
  OUTCOME = 'OUTCOME',
  STRATEGY = 'STRATEGY',
  ACTION_VERB = 'ACTION_VERB',
}

const ratingSchema = z.object({
  category: z.nativeEnum(BulletPointsCategory),
  rate: z.number(),
  text: z.string(),
  explanation: z.string(),
});
const bulletPointsSchema = z.object({
  id: z.number(),
  isValidated: z.boolean(),
  jobTitle: z.string().nullable(),
  level: z.nativeEnum(JobLevelEnum).nullable(),
  ratings: z.array(ratingSchema),
  text: z.string(),
  totalRate: z.number().nullable(),
  companies: z.array(z.number()),
  unregisteredCompanies: z.array(z.string()),
});
export type BulletPointsType = z.infer<typeof bulletPointsSchema>;

export const bulletPointsGeneratedFromText = z.object({
  generatedBy: z.number().nullable(),
  text: z.string(),
  createdAt: z.string(),
  isGenerated: z.boolean(),
});

const generatedBulletPointsSchema = z
  .object({ generatedBulletPoints: z.array(bulletPointsSchema) })
  .merge(bulletPointsGeneratedFromText);

export const getBulletPoints = async ({
  offset = 0,
  pageSize = 999,
  ...formData
}: {
  jobTitle?: string;
  level?: JobLevelEnum;
  unregisteredCompanies?: string;
  companies?: number;
  offset?: number;
  pageSize?: number;
}) => {
  const { data } = await axios.get(apiRoutes.bulletPoints.points._root, {
    params: {
      ...formData,
      job_title: formData.jobTitle,
      unregistered_companies: formData.unregisteredCompanies,
      limit: pageSize,
      offset,
      order: '-total_rate',
    },
  });
  return DjangoDataZodSchema(bulletPointsSchema).parse(data);
};

export const generateBulletPointsFromText = async (text: string) => {
  const { data } = await authService.post(
    apiRoutes.bulletPoints.fromText.generateFromText,
    { text }
  );
  return bulletPointsGeneratedFromText
    .merge(z.object({ id: z.number() }))
    .parse(data);
};

export const getDetailBulletPoints = async (id: number) => {
  const { data } = await authService.get(
    apiRoutes.bulletPoints.fromText.detail(id)
  );
  return generatedBulletPointsSchema.parse(data);
};
