// eslint-disable-next-line import/no-cycle
import { Button as BaseButton } from '@mindpal-co/mindpal-ui';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import classNames from 'classnames';
import {
  type ComponentPropsWithoutRef,
  type ForwardedRef,
  forwardRef,
  type PropsWithChildren,
} from 'react';

// eslint-disable-next-line import/no-cycle
import ConfirmationDialog from './ConfirmationDialog';

export type ButtonVariantProps = Omit<
  VariantProps<typeof buttonStyles>,
  'isSplit'
>;
type Props = ComponentPropsWithoutRef<typeof BaseButton> & ButtonVariantProps;

export const radialGradientBackgroundTransition = (className: string) =>
  `relative z-[1] overflow-hidden duration-400 !ease-custom-primary-button before:absolute before:bottom-0 before:left-1/2 before:z-[-1] before:aspect-square before:w-[110%] before:-translate-x-1/2 before:translate-y-[110%] before:rounded-full before:transition-all before:content-[''] before:duration-400 before:!ease-custom-primary-button after:absolute after:h-[100%] after:w-[100%] after:rounded-lg after:border-[2px] after:border-transparent after:transition-colors after:left-0 after:bottom-0 after:content-[''] focus-within:before:bottom-1/2 focus-within:before:translate-y-1/2 hover:before:bottom-1/2 hover:before:translate-y-1/2 ${className}`;

export const buttonStyles = cva(
  'text-button-text flex select-none items-center justify-center gap-2 rounded-lg outline-none transition-colors disabled:cursor-not-allowed disabled:opacity-30',
  {
    variants: {
      variant: {
        primary: `bg-gradient-to-l from-[#3755E0] to-[#561DE2] duration-400 !ease-custom-primary-button ${radialGradientBackgroundTransition(
          'before:bg-primary-800 focus-within:after:border-neutral-300'
        )}`,
        jbGradient: `bg-jb-primary-gradient duration-400 !ease-custom-primary-button ${radialGradientBackgroundTransition(
          'before:bg-[#E87556] focus-within:after:border-neutral-300'
        )}`,
        jbPrimary: `bg-jb-primary-500 ${radialGradientBackgroundTransition(
          'before:bg-jb-primary-600 focus-within:after:border-neutral-300 font-medium'
        )}`,
        jbGradientBorder: 'bg-candidate-gradient-border text-neutral-1000',
        primaryLight: `bg-primary-500 duration-400 !ease-custom-primary-button ${radialGradientBackgroundTransition(
          'before:bg-primary-800 focus-within:after:border-neutral-300'
        )}`,
        secondary: `border border-primary-600 bg-neutral-900 focus-within:border-transparent  hover:text-neutral-900  focus:text-neutral-900 active:text-neutral-900 ${radialGradientBackgroundTransition(
          'before:bg-neutral-100 focus-within:after:border-neutral-600'
        )}`,
        gray: ` border border-primary-600 bg-neutral-900 text-neutral-100 ${radialGradientBackgroundTransition(
          'before:bg-neutral-700 focus-within:after:border-primary-300'
        )}`,
        tertiary:
          "relative z-[1] !rounded-lg bg-gradient-to-l from-[#885AFD] to-[#00193F] before:absolute before:left-0 before:top-0 before:z-[-1] before:h-full before:w-full before:rounded-lg before:border-[2px] before:border-transparent before:bg-primary-800 before:opacity-0  before:transition-all before:content-[''] hover:before:opacity-100 focus:before:border-neutral-300 focus:before:opacity-100 active:before:opacity-100",
        quaternary:
          'border-[2px] border-primary-600 bg-transparent text-black hover:bg-primary-700 hover:text-neutral-100 focus:border-neutral-300 focus:bg-primary-700 focus:text-neutral-100',
        'candidate-black':
          'border border-transparent bg-[#202020] font-semibold text-white focus-within:border-[#9585FA]',
        'candidate-white':
          'border border-[#7C6D6D] bg-[#fff] font-semibold text-[#7C6D6D] focus-within:border-[#9585FA]',
        unstyled: 'disabled:text-neutral-600',
        delete:
          'bg-jb-alert-100 text-jb-alert-600 hover:bg-jb-alert-600 hover:text-jb-alert-100',
        'candidate-secondary': `text-candidateButton border border-neutral-400 bg-neutral-0 text-sm font-medium shadow-sm hover:bg-neutral-200`,
        'candidate-teritariary':
          'border-none font-medium text-jb-neutral-700 hover:text-neutral-500 disabled:text-neutral-500',
        'candidate-quaternary':
          'border-none font-medium text-jb-primary-500 hover:text-jb-primary-400 disabled:text-jb-primary-300',
        communityRectangular:
          'rounded-md border bg-jb-neutral-200 hover:bg-jb-neutral-300 active:border-jb-primary-500',
        candidateSecondaryGray: 'bg-jb-neutral-700 text-white',
      },
      size: {
        xs: 'p-0',
        sm: 'p-2',
        md: 'px-5 py-3',
        long: 'px-[3.25rem] py-3',
        'candidate-md': 'px-6 py-2',
        'candidate-sm': 'px-4 py-1',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  }
);

const Button = (
  {
    children,
    size,
    variant,
    className,
    extraActionsTriggerButtonClassName,
    extraActionsButtonClassName,
    popoverContentProps,
    popoverTriggerProps,
    tooltipProps,
    extraActions,
    ...props
  }: PropsWithChildren<Props>,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  return (
    <BaseButton
      {...props}
      className={classNames(
        buttonStyles({ variant, size }),
        'text-body-small font-inter',
        className
      )}
      extraActionsTriggerButtonClassName={classNames(
        extraActionsTriggerButtonClassName,
        '!rounded-l-none border-l-2 h-full before:!w-[180%] after:!rounded-l-none',
        buttonStyles({ variant, size: size !== 'long' ? size : 'md' })
      )}
      popoverContentProps={{
        className: 'border-slate-800 bg-slate-950 text-slate-50',
        ...popoverContentProps,
      }}
      extraActionsButtonClassName={classNames(
        '!justify-start font-semibold',
        buttonStyles({ variant: 'unstyled' }),
        extraActionsButtonClassName
      )}
      popoverTriggerProps={{ disabled: props.disabled, ...popoverTriggerProps }}
      extraActionsTriggerButtonProps={{ disabled: props.disabled }}
      tooltipProps={{
        delayDuration: 350,
        ...tooltipProps,
      }}
      ConfirmationDialog={ConfirmationDialog}
      extraActions={extraActions?.map(
        ({
          ConfirmationDialog: PassedConfirmationDialog,
          ...extraActionsProps
        }) => ({
          ...extraActionsProps,
          ConfirmationDialog: PassedConfirmationDialog ?? ConfirmationDialog,
        })
      )}
      ref={ref}
    >
      {children}
    </BaseButton>
  );
};

export default forwardRef(Button);
