import { z } from 'zod';

import authService from '@/services/auth/auth';

import { apiRoutes } from './config';

export const urlRegex =
  /^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.*$|^$/;

export enum EnumLevelLanguage {
  BEGINNER = 'BEGINNER',
  ELEMENTARY = 'ELEMENTARY',
  INTERMEDIATE = 'INTERMEDIATE',
  UPPER_INTERMEDIATE = 'UPPER_INTERMEDIATE',
  ADVANCED = 'ADVANCED',
  PROFICIENCY = 'PROFICIENCY',
  NATIVE = 'NATIVE',
}

export enum EnumJobType {
  CONSULTANT = 'CONSULTANT',
  FREELANCER = 'FREELANCER',
  INTERN = 'INTERN',
  NINE_FIVE = 'NINE_FIVE',
  PART_TIME = 'PART_TIME',
  NOT_SPECIFIED = '',
}

const addRequiredId = <T extends z.ZodRawShape>(schema: z.ZodObject<T>) =>
  schema.extend({
    id: z.number(),
  });

const addOptionalId = <T extends z.ZodRawShape>(schema: z.ZodObject<T>) =>
  schema.extend({
    id: z.number().optional(),
  });

const educationSchema = z.object({
  schoolName: z.string(),
  address: z.string(),
  degree: z.string(),
  major: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  description: z.string(),
});
const languagesSchema = z.object({
  name: z.string(),
  level: z.nativeEnum(EnumLevelLanguage),
});
const certificatesSchema = z.object({
  name: z.string(),
  url: z.string(),
  date: z.string(),
});

const workExperienceProjectsSchema = z.object({
  name: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  description: z.string(),
  client: z.string(),
  url: z.string(),
  technologies: z.array(z.string()).optional(),
  industry: z.string(),
  workExperience: z.number().optional(),
  id: z.number().optional(),
});

const workExperienceSchema = z.object({
  company: z.string(),
  jobTitle: z.string(),
  jobType: z.nativeEnum(EnumJobType),
  address: z.string(),
  technologies: z.array(z.string()),
  startDate: z.string(),
  endDate: z.string(),
  description: z.string(),
  projects: z.array(workExperienceProjectsSchema),
  industry: z.string(),
});
const projectsSchema = z.object({
  name: z.string(),
  description: z.string(),
  url: z.string(),
  technologies: z.array(z.string()),
  startDate: z.string(),
  endDate: z.string(),
});
const achievementsSchema = z.object({
  eventName: z.string(),
  description: z.string(),
  date: z.string(),
});
const linksSchema = z.object({
  label: z.string(),
  url: z.string(),
});
const hardSkillsSchema = z.object({
  name: z.string(),
  level: z.string(),
  experience: z.number(),
});

const extraActivitiesSchema = z.object({
  name: z.string(),
  description: z.string(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  oneDayEvent: z.boolean(),
});

const commonCandidateProfileMeSchema = z.object({
  resumeProcessed: z.boolean(),
  name: z.string(),
  email: z.string(),
  phoneNumber: z.string(),
  linkedinUrl: z.string(),
  githubUrl: z.string(),
  address: z.string(),
  softSkills: z.object({
    softSkillsAi: z.array(z.string()),
    softSkillsUser: z.array(z.string()),
  }),
  hobbies: z.array(z.string()),
  userCompleted: z.boolean(),
});

const candidateProfileMeSchema = commonCandidateProfileMeSchema.extend({
  education: z.array(addRequiredId(educationSchema)),
  languages: z.array(addRequiredId(languagesSchema)),
  certificates: z.array(addRequiredId(certificatesSchema)),
  workExperience: z.array(addRequiredId(workExperienceSchema)),
  projects: z.array(addRequiredId(projectsSchema)),
  achievements: z.array(addRequiredId(achievementsSchema)).optional(),
  hardSkills: z.object({
    hardSkillsAi: z.array(addRequiredId(hardSkillsSchema)),
    hardSkillsUser: z.array(addRequiredId(hardSkillsSchema)),
  }),
  links: z.array(addRequiredId(linksSchema)).optional(),
  extraActivities: z.array(addRequiredId(extraActivitiesSchema)),
});

const candidateProfileMePatchSchema = commonCandidateProfileMeSchema.extend({
  education: z.array(addOptionalId(educationSchema)),
  languages: z.array(addOptionalId(languagesSchema)),
  certificates: z.array(addOptionalId(certificatesSchema)),
  workExperience: z.array(addOptionalId(workExperienceSchema)),
  projects: z.array(addOptionalId(projectsSchema)),
  achievements: z.array(addOptionalId(achievementsSchema)).optional(),
  hardSkills: z.object({
    hardSkillsAi: z.array(addOptionalId(hardSkillsSchema)),
    hardSkillsUser: z.array(addOptionalId(hardSkillsSchema)),
  }),
  links: z.array(addOptionalId(linksSchema)).optional(),
  extraActivities: z.array(addOptionalId(extraActivitiesSchema)),
});

export type CandidateProfileMe = z.infer<typeof candidateProfileMeSchema>;
export type CandidateProfileMePatch = z.infer<
  typeof candidateProfileMePatchSchema
>;

export type BasicSuggestions = {
  query?: string;
  offset?: number;
  limit?: number;
};

export const getCandidateProfileMe = async () => {
  const res = await authService.get(apiRoutes.candidateProfile.me);
  if (res.status === 200) {
    return candidateProfileMeSchema.parse(res.data);
  }
  if (res.status === 204) {
    return null;
  }
};

export const getCandidateProfileDetail = async (id: number) => {
  const { data } = await authService.get(
    apiRoutes.candidateProfile.detail(id)._root
  );
  return candidateProfileMeSchema.parse(data);
};

export const patchCandidateProfileMe = async (
  data: Partial<CandidateProfileMePatch>
) => authService.patch(apiRoutes.candidateProfile.me, data);

export const postCandidateProfileFile = async (data: File | Blob) =>
  authService.post(
    apiRoutes.candidateProfile.fromFile,
    { cv_file: data },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
