import type { FC } from 'react';

import type { BulletPointsType } from '@/services/bullet-points';

import LoadingPlaceholder from '../LoadingPlaceholder';
import Text from '../Text';
import BulletPointHighlightSpan from './BulletPointHighlightSpan';

type Props = {
  bulletPointsList?: BulletPointsType[];
  className?: string;
  isLoading?: boolean;
};
const BulletPointsList: FC<Props> = ({
  bulletPointsList = [],
  className,
  isLoading,
}) => {
  const isBulletPointsGenerated = !isLoading && bulletPointsList.length > 0;
  return (
    <div className={className}>
      {isBulletPointsGenerated && (
        <ul className="marker:text-jb-neutral-800' flex w-full list-disc flex-col gap-6 p-2 pl-4 marker:text-h4 md:!p-6 md:!pl-10">
          {bulletPointsList.map((bullet) => (
            <li className="text-neutral-1000" key={bullet.text}>
              <Text
                variant="jb-body-large"
                className="whitespace-pre-wrap !leading-8"
              >
                <HighlightText bullet={bullet} />
              </Text>
            </li>
          ))}
        </ul>
      )}
      {isLoading && (
        <div className="flex h-full flex-col items-center justify-center">
          <div className="flex flex-col gap-2">
            <LoadingPlaceholder />
            <Text variant="jb-body-medium" className="text-jb-neutral-700">
              This may take a while...
            </Text>
          </div>
        </div>
      )}
      {!isBulletPointsGenerated && (
        <div className="flex h-full w-full items-center justify-center">
          <Text variant="jb-body-large" className="text-jb-neutral-700">
            No bullet points generated
          </Text>
        </div>
      )}
    </div>
  );
};
export default BulletPointsList;

const HighlightText = ({ bullet }: { bullet: BulletPointsType }) => {
  const valuesToHighlight = bullet.ratings
    .map((e) => e)
    .filter((e) => e.text !== '' && ' ');

  const escapeRegExp = (text: string) =>
    text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const regex = new RegExp(
    `(${valuesToHighlight.map((e) => escapeRegExp(e.text)).join('|')})`,
    'g'
  );
  const parts = bullet.text.split(regex).filter((part) => part?.trim() !== '');

  const findValueToHighlight = (part: string) =>
    valuesToHighlight.find((value) => value.text === part);

  return (
    <>
      {parts.map((part) =>
        findValueToHighlight(part) ? (
          <BulletPointHighlightSpan
            key={part}
            bulletPointsCategory={findValueToHighlight(part)!.category}
          >
            {part}
          </BulletPointHighlightSpan>
        ) : (
          part
        )
      )}
    </>
  );
};
