import classNames from 'classnames';
import { type FC } from 'react';

import {
  BulletPointsCategory,
  type BulletPointsType,
} from '@/services/bullet-points';
import { capitalizeFirstLetter } from '@/utils/cvGenerator';

import Text from '../Text';
import { Progress } from '../ui/progress';
import { BulletPointsCategoryColor } from './BulletPointHighlightSpan';
import BulletPointsTooltip from './BulletPointsTooltip';

const initialCategoryRatesSums = {
  [BulletPointsCategory.ACTION_VERB]: null,
  [BulletPointsCategory.STRATEGY]: null,
  [BulletPointsCategory.OUTCOME]: null,
  [BulletPointsCategory.METRIC]: null,
};

const tooltipsByCategory = {
  [BulletPointsCategory.ACTION_VERB]: () => (
    <>
      <Text className="font-semibold" variant="jb-body-small">
        Action Verbs
      </Text>
      <Text variant="jb-body-small">
        Start your bullet point with a strong, action-oriented word like led,
        developed, or optimized. This sets the tone for impact and highlights
        your role.
      </Text>
    </>
  ),
  [BulletPointsCategory.STRATEGY]: () => (
    <>
      <Text className="font-semibold" variant="jb-body-small">
        Strategy
      </Text>
      <Text variant="jb-body-small">
        Describe the specific methods or approaches you used to achieve your
        goal. This gives context to your work and shows your problem-solving
        skills.
      </Text>
    </>
  ),
  [BulletPointsCategory.OUTCOME]: () => (
    <>
      <Text className="font-semibold" variant="jb-body-small">
        Outcome
      </Text>
      <Text variant="jb-body-small">
        Highlight the tangible results of your efforts. What did you accomplish?
        Focus on the success or value your actions brought to the project or
        organization.
      </Text>
    </>
  ),
  [BulletPointsCategory.METRIC]: () => (
    <>
      <Text className="font-semibold" variant="jb-body-small">
        Metric
      </Text>
      <Text variant="jb-body-small">
        Use numbers to quantify your impact. Whether it’s percentages, dollar
        amounts, or time saved, metrics make your achievements concrete and
        impressive.
      </Text>
    </>
  ),
};

type CategoryScoreType = {
  category: BulletPointsCategory;
  categoryName: string;
  rate: number;
};

type Props = {
  bulletPointsList: BulletPointsType[];
  className?: string;
};
const BulletPointsScoreOverview: FC<Props> = ({
  bulletPointsList,
  className,
}) => {
  const categories = Object.values(BulletPointsCategory);
  const bulletPointsCount = bulletPointsList.length;

  const calculateCategoryRateSum = (
    initialValue: number | null,
    bulletPoint: BulletPointsType,
    category: BulletPointsCategory
  ) => {
    const effectiveCategorySum = initialValue ?? 0;
    const categoryRates = bulletPoint.ratings
      .filter((rate) => rate.category === category)
      .map((rate) => rate.rate);

    const categoryRatesSums = categoryRates.reduce(
      (acc, rate) => acc + rate,
      0
    );

    return effectiveCategorySum + categoryRatesSums;
  };

  const totalRateSum = bulletPointsList.reduce<null | number>(
    (acc, bulletPoint) => {
      return (acc ?? 0) + (bulletPoint.totalRate ?? 0);
    },
    null
  );

  const categoryRatesSum = bulletPointsList.reduce((acc, bulletPoint) => {
    return Object.fromEntries(
      categories.map((category) => [
        [category],
        calculateCategoryRateSum(acc[category], bulletPoint, category),
      ])
    );
  }, initialCategoryRatesSums);

  const categoryScore: CategoryScoreType[] = Object.entries(
    categoryRatesSum
  ).map(([category, categorySum]) => ({
    category: category as BulletPointsCategory,
    categoryName: capitalizeFirstLetter(category).replaceAll('_', ' '),
    rate: categorySum ? Math.round(categorySum / bulletPointsCount) : 0,
  }));

  const totalRateAvg = (totalRateSum ?? 0) / bulletPointsCount;
  return (
    <div
      className={classNames(
        'px-6 py-4 flex items-center flex-col gap-6',
        className
      )}
    >
      <div className="flex flex-col gap-1">
        <Text variant="h3" className="text-center font-semibold">
          {totalRateSum ? Math.round(totalRateAvg) : '--'}%
        </Text>
        <div className="flex flex-row gap-2">
          <Text
            variant="jb-body-large"
            className="flec-row flex items-center gap-2 font-semibold text-jb-neutral-700"
          >
            Bullet Score
          </Text>
          <BulletPointsTooltip>
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <Text className="font-semibold" variant="jb-body-small">
                  Bullet Score
                </Text>
                <Text variant="jb-body-small">
                  Bullet points are a powerful way to showcase the impact of
                  your actions and present your experience in a concise yet
                  impactful format. By refining your bullet points, you can
                  convey more information with fewer words, making your
                  experience stand out to readers. This is why achieving a high
                  Bullet Score is essential - it reflects how effectively your
                  bullet points highlight your accomplishments.
                </Text>
              </div>
              <div className="flex flex-col gap-2">
                <Text className="font-semibold" variant="jb-body-small">
                  How to Improve Your Bullet Score?
                </Text>
                <Text variant="jb-body-small">
                  The more detailed your initial text, the better we can refine
                  your bullet points. Adding more context and specific details
                  helps create stronger and more impactful bullet points.
                  Remember, the bullets are generated based on the text you
                  provide - to achieve a higher score, provide richer context
                  and content in your original input. The more complete and
                  precise your details, the higher the impact of your bullet
                  points.
                </Text>
              </div>
            </div>
          </BulletPointsTooltip>
        </div>
      </div>
      <Progress className="h-5 bg-neutral-200" value={totalRateAvg} max={100} />
      <div className="w-full">
        {categoryScore.map(({ category, categoryName, rate }) => (
          <CategoryScoreItem
            key={categoryName}
            category={category}
            categoryName={categoryName}
            rate={rate}
            isBulletPointsGenerated={!!totalRateSum}
          />
        ))}
      </div>
    </div>
  );
};

export default BulletPointsScoreOverview;

type CategoryScoreItemProps = CategoryScoreType & {
  isBulletPointsGenerated: boolean;
};
const CategoryScoreItem: FC<CategoryScoreItemProps> = ({
  category,
  categoryName,
  rate,
  isBulletPointsGenerated,
}) => {
  const categoryColor = BulletPointsCategoryColor[category];
  return (
    <div className="flex w-full flex-row items-center justify-between border-b border-jb-neutral-400 px-2 py-4">
      <div className="flex flex-row items-center gap-2">
        <div
          className="aspect-square h-5 rounded-full"
          style={{ backgroundColor: categoryColor }}
        />
        <Text variant="jb-body-large" className="text-jb-neutral-700">
          {categoryName}
        </Text>
        <BulletPointsTooltip>
          <div className="flex flex-col gap-2">
            {tooltipsByCategory[category]()}
          </div>
        </BulletPointsTooltip>
      </div>
      <div className="flex flex-row items-center gap-2">
        <div className="flex flex-row-reverse gap-1">
          {Array.from({ length: 2 }).map((_, index) => (
            <div
              key={JSON.stringify(`${category} - ${index}`)}
              style={{
                backgroundColor: rate > index ? categoryColor : undefined,
              }}
              className="aspect-square h-3 rounded-full bg-jb-neutral-300"
            />
          ))}
        </div>
        <Text className="font-semibold text-jb-neutral-800">
          {isBulletPointsGenerated ? `${rate}/2` : '--'}
        </Text>
      </div>
    </div>
  );
};
